import Houdini from 'houdinijs/dist/js/houdini.polyfills';
import { getParents, passiveSupported } from './utility';

//
// NAVIGATION MENU
//
// toggle menu with menu-icon
const navbarBurger = document.querySelector('.navbar-burger');
const navbar = document.querySelector('.navbar');
if (navbar && navbarBurger) {
  navbarBurger.addEventListener('click', (e) => {
    e.preventDefault();
    navbar.classList.toggle('is-active');
    navbarBurger.classList.toggle('is-active');
  });
}
//
// shrink navbar on scrolling
const onScroll = () => {
  const shrink = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;
  navbar.classList.toggle('shrink', shrink);
};
if (navbar) {
  window.addEventListener('scroll', onScroll, passiveSupported ? { passive: true } : false);
}
//
// LANG SWITCHER
//
const localeDropdown = document.querySelector('.locale-dropdown');
const localeDropdownTrigger = document.querySelector('.locale-dropdown .dropdown-trigger');
if (localeDropdown && localeDropdownTrigger) {
  localeDropdownTrigger.addEventListener('click', () => {
    localeDropdown.classList.toggle('is-active');
  });
}
//
// Add query params to language switcher
// prevents checkout from loosing products, coupons and other stuff
//

const elements = document.querySelectorAll('.locale > a');
Array.prototype.forEach.call(elements, (el) => {
  // eslint-disable-next-line no-param-reassign
  el.href += window.location.search;
});

//
// Initialize accordions
//
const accordion = new Houdini('[data-houdini-group]', {
  isAccordion: true,
});

// Open accordion from URL hash
if (window.location.hash) {
  const hash = window.location.hash.substring(1);
  const hashEl = document.getElementById(hash);

  if (hashEl) {
    // Expand target element
    accordion.expand(`#${hash}`);

    // Expand parents
    const parents = getParents(hashEl, '[data-houdini-group]');
    Array.prototype.forEach.call(parents, accordion.expand);

    // Scroll to element
    document.getElementById(hash).scrollIntoView();
  }
}
